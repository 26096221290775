









































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic
} from "@/configuration";
import DniField from "@/components/DniField.vue";
import CurrencyInput from "@/components/Currency.vue";
import PaymentGateways from "@/components/common/PaymentGateways.vue";
import API from "@/api";
import moment from "moment";
import { AccountTrim } from "@/utils";

@Component({
  metaInfo: {
    title: "Pago pendiente",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      transferenceDialog: false,
      project: null,
      pendingPayment: null,
      isLoading: true,
      currencyDisabled: false,
      transferDialog: false,
      canGoNext: false,
      showInfo: false,
      canShow: false,
      reportPayment: {
        name: null,
        account: "",
        rut: "",
        bank: null,
        paymentType: null,
        amount: 1,
        transferStep: 0,
        code: ""
      },
      rules: {
        notEmpty: [(v: any) => !!v || "Este campo es requerido"],
        name: [
          (v: any) => !!v || "Nombre requerido",
          (v: any) => !/[0-9]/g.test(v) || "Nombre no puede contener numeros"
        ],
        account: [
          (v: any) => !!v || "Numero de cuenta es requerido",
          (v: any) =>
            !/[^\d]/gim.test(v) ||
            "La cuenta debe tener sólo número (Sin guiones)",
          (v: any) => v.length >= 6 || "Tiene que ser mayor o igual a 6"
        ],
        lastname: [
          (v: any) => !!v || "Apellido requerido",
          (v: any) => !/[0-9]/g.test(v) || "Apellido no puede contener numeros"
        ],
        address: [(v: any) => !!v || "Dirección es requerido"],
        bank: [(v: any) => !!v || "Tienes que seleccionar un banco"],
        accountType: [
          (v: any) => !!v || "Tienes que seleccionar un tipo de cuenta"
        ]
      },
      paymentTypes: [
        {
          id: 1,
          text: "Solo reserva",
          disabled: false
        },
        {
          id: 2,
          text: "Abono",
          disabled: false
        },
        {
          id: 3,
          text: "Monto completo",
          disabled: false
        }
      ],
      statics: {
        banks: null
      },
      filterThis: false
    };
  },
  watch: {
    reportPayment: {
      handler(val: any) {
        switch (val.transferStep) {
          case 0:
            // @ts-ignore
            if (this.$refs.reportPayment.validate()) {
              this.$data.canGoNext = true;
            } else {
              this.$data.canGoNext = false;
            }
            break;
          case 1:
            this.$data.canGoNext = true;
            break;
          case 2:
            this.$data.canGoNext = true;
            break;
        }
      },
      deep: true
    },
    "reportPayment.paymentType": {
      handler(val: number) {
        switch (val) {
          case 1:
            this.$data.reportPayment.amount = this.$data.pendingPayment.derechos;
            this.$data.currencyDisabled = true;
            break;
          case 2:
            this.$data.reportPayment.amount = this.$data.pendingPayment.saldo_pendiente;
            this.$data.currencyDisabled = false;
            break;
          case 3:
            this.$data.reportPayment.amount = this.$data.pendingPayment.saldo_pendiente;
            this.$data.currencyDisabled = true;
            break;
        }
      },
      deep: true
    }
  },
  async beforeMount() {
    let self = this;
    //let pendingPayments : any = await API.getPendingPayments()

    let pendingPayment: any = await API.investment.normal(
      this.$props.paymentId
    );

    this.$data.statics.banks = await API.getBanks();

    this.$data.project = await API.getProject(pendingPayment.proyecto_id);

    this.$data.pendingPayment = pendingPayment;

    if (this.$data.project.credito_tipo_id == 1) {
      if (new Date() >= this.$data.project.fecha_entrega_dinero) {
        this.$data.filterThis = true;
      }
    }

    if (pendingPayment.reservado == 1) {
      this.$data.paymentTypes[0].disabled = true;
      this.$data.reportPayment.amount =
        pendingPayment.monto +
        pendingPayment.saldo_pendiente -
        pendingPayment.derechos;
      this.$data.reportPayment.paymentType = 2;
      this.$data.currencyDisabled = true;
    } else {
      this.$data.reportPayment.amount = pendingPayment.derechos;
      this.$data.reportPayment.paymentType = 1;
      this.$data.currencyDisabled = false;
    }

    if (pendingPayment.pago_inversionista != null) {
      pendingPayment.pago_inversionista.pago_detalles.forEach(
        (detalle: any) => {
          if (detalle.pago_deta_tipo_id == 1) {
            this.$data.paymentTypes[0].disabled = true;
            this.$data.reportPayment.amount =
              pendingPayment.monto +
              pendingPayment.saldo_pendiente -
              pendingPayment.derechos;
            this.$data.reportPayment.paymentType = 2;
            this.$data.currencyDisabled = true;
          }
        }
      );
    }

    let userBank: any = await API.getUserBank(this.$store.state.user.id);

    this.$data.reportPayment.name = `${this.$store.state.user.nombre} ${this.$store.state.user.app_pat} ${this.$store.state.user.app_mat}`;
    this.$data.reportPayment.rut = this.$store.state.user.rut;
    this.$data.reportPayment.account = AccountTrim.string(userBank.numero);
    this.$data.reportPayment.bank = userBank.banco_id;

    this.$data.isLoading = false;
    this.$data.reportPayment.paymentType = 3;

    this.$data.canShow = true;
    // this.$data.reportPayment.paymentType
    // console.log(this.$data.paymentTypes.filter(f => f.id > 1))
    // this.$data.reportPayment.paymentType = this.$data.reportPayment.paymentType.filter((f:number) => f >= 2)
  },
  methods: {
    subjectText() {
      switch (this.$data.reportPayment.paymentType) {
        case 1:
          return "Reserva";
        case 2:
          return "Abono";
        case 3:
          return "Total";
      }
    },
    async createPayment(id: number) {
      switch (id) {
        case 1:
          this.$data.reportPayment.code = await API.getUniqueCode();
          this.$data.transferDialog = true;
          break;
        case 2:
        case 3:
          // @ts-ignore
          this.gatewayPayment(id);
          break;
      }
    },
    async transferencePayment() {
      let uniqueCode: any = await API.getUniqueCode();
      let payload = {
        cod: uniqueCode,
        investmentId: this.$data.pendingPayment.id,
        amount: this.$data.reportPayment.amount,
        bank: this.$data.reportPayment.bank,
        accountNumber: AccountTrim.string(this.$data.reportPayment.account),
        name: this.$data.reportPayment.name,
        dni: this.$data.reportPayment.rut,
        paymentType: this.$data.reportPayment.paymentType,
        date: moment(Date.now()).format("YYYY-MM-DD")
      };

      API.payment.report
        .transference(
          this.$data.project.id,
          this.$data.reportPayment.amount,
          payload
        )
        .then(res => {
          this.$router.push({
            name: "dashboard-investor-pending-payments"
          });
        })
        .catch(error => {
          // Error al crear la transferencia
        })
        .finally(() => {
          // this.$data.transactionCompleted = true
        });
    },
    async gatewayPayment(id: number) {
      // let uniqueCode: any = await API.getUniqueCode()

      // @ts-ignore
      let subjectText = this.subjectText();

      let payload = {
        investmentId: this.$data.pendingPayment.id,
        amount: this.$data.reportPayment.amount,
        transaction: `${this.$data.project.id}`,
        paymentType: this.$data.reportPayment.paymentType,
        subject: `Pagando "${subjectText}" del proyecto "${this.$data.project.nombre}"`
      };

      API.payment.pending
        .gateway(payload)
        .then((data: any) => {
          this.$store.commit("setGatewayTransaction", data.data.transaccion);
          this.$store.commit("setTransactionPendingPayment");
          this.$store.commit("setTransactionPaymentType", payload.paymentType);
          window.location.href = data.data.payment_url;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getPendingPaymentAmount(status: number) {
      if (this.$data.pendingPayment.pago_inversionista == null) {
        return 0;
      } else {
        return this.$data.pendingPayment.pago_inversionista.pago_detalles
          .filter((pago: any) => pago.confirmado == status)
          .map((pago: any) => pago.monto)
          .reduce((last: number, current: number) => last + current, 0);
      }
    }
  },
  components: {
    DniField,
    CurrencyInput,
    PaymentGateways
  }
})
export default class PayPendingPayment extends Vue {
  @Prop() paymentId!: number;
  @Prop() cod!: any;
}
